import React, { useEffect, useState } from 'react';
import './Codex.css';
import { useNavigate } from 'react-router-dom';

const Codex = () => {
    const [codexItems, setCodexItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sortOption, setSortOption] = useState('power');
    const navigate = useNavigate();

    const userId = '3428d458-7051-7001-444d-f6afbd8b8f93';
    const apiUrl = `https://vppmbp4zp4.execute-api.us-east-1.amazonaws.com/api/v1/codex`;
    const token = localStorage.getItem('idToken');

    const calculatePower = (codexId, rarity) => {
        const power = Math.round(100 * Math.pow(2, codexId / 100) * parseInt(rarity) / 2);
        const units = ["", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
        let unitIndex = 0;
        let displayPower = power;

        while (displayPower >= 1000000 && unitIndex < units.length - 1) {
            displayPower /= 1000000;
            unitIndex++;
        }

        return `${Math.round(displayPower).toLocaleString()} ${units[unitIndex]}`.trim();
    };

    useEffect(() => {
        fetch(apiUrl, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (response.status === 401) {
                    localStorage.removeItem('idToken');
                    navigate('/');
                    return null;
                }
                return response.json();
            })
            .then(data => {
                if (data) {
                    setCodexItems(data);
                }
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, [apiUrl, navigate, token]);

    const handleSortChange = (e) => {
        setSortOption(e.target.value);
    };

	const sortedItems = [...codexItems].sort((a, b) => {
		if (sortOption === 'power') {
			const powerA = calculatePower(a.codexId, a.rarity);
			const powerB = calculatePower(b.codexId, b.rarity);
			return powerB.localeCompare(powerA, undefined, { numeric: true, sensitivity: 'base' });
		} else if (sortOption === 'rarity') {
			return b.rarity - a.rarity;
		}
		return 0;
	});


    if (loading) {
        return (
            <div className="loading-screen">
                <span>Loading...</span>
            </div>
        );
    }

    const getRandomEffectClass = () => {
        const effectNumber = Math.floor(Math.random() * 8) + 1;
        return `box effect${effectNumber}`;
    };

    const getRarityClass = (rarity) => {
        switch (rarity) {
            case "1":
                return 'border-grey';
            case "2":
                return 'border-green';
            case "3":
                return 'border-blue';
            case "4":
                return 'border-purple';
            case "5":
                return 'border-orange';
            default:
                return '';
        }
    };
	const handleNavigateToCodex = () => {
		navigate('/codex'); // Navigate to the /codex page
	};
	const handleNavigateToGacha = () => {
		navigate('/gacha'); // Navigate to the /codex page
	};	    

    return (
        <div className="codex-container">
            <div style={{ display: 'flex', gap: '10px' }}>
                <button 
                    onClick={handleNavigateToCodex} 
                    className="codex-button" 
                    style={{ display: 'inline-block' }}
                >
                    Go to Codex
                </button>
                <button 
                    onClick={handleNavigateToGacha} 
                    className="codex-button" 
                    style={{ display: 'inline-block' }}
                >
                    Go to Gacha
                </button>
            </div>            
            <div className="sort-dropdown">
                <label htmlFor="sort">Sort by:</label>
                <select id="sort" value={sortOption} onChange={handleSortChange}>
                    <option value="power">Power</option>
                    <option value="rarity">Rarity</option>
                </select>
            </div>
            <div className="card-container">
                {sortedItems.map((item, index) => (
                    <div key={index} className={`card ${getRarityClass(item.rarity)} ${getRandomEffectClass()}`}>
                        <div className="word">{item.word}</div>
                        <div className="definition">{item.definition}</div>
                        <div className="power">{calculatePower(item.codexId, item.rarity)}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Codex;
