// src/Register.js
import React, { useState } from 'react';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';

const poolData = {
    UserPoolId: 'us-east-1_VHDa4Ftzs', // Replace with your User Pool ID
    ClientId: '1bscmli1vv0cd0hfipcfanht6r'   // Replace with your App Client ID
};

const userPool = new CognitoUserPool(poolData);

const Register = ({ onAuthenticated })  => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [isRegistered, setIsRegistered] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleRegister = (event) => {
        event.preventDefault();
        userPool.signUp(email, password, [{ Name: 'email', Value: email }], null, (err, result) => {
            if (err) {
                setError(err.message || JSON.stringify(err));
                setMessage('');
                return;
            }
            setError('');
            setMessage('Registration successful! Please check your email for the verification code.');
            setIsRegistered(true);
        });
    };

    const handleVerify = (event) => {
        event.preventDefault();
        const cognitoUser = new CognitoUser({
            Username: email,
            Pool: userPool
        });
        cognitoUser.confirmRegistration(verificationCode, true, (err, result) => {
            if (err) {
                setError(err.message || JSON.stringify(err));
                return;
            }
            setError('');
            setMessage('Verification successful! You can now log in.');
            onAuthenticated(true);  // Update authentication state to true
        });
    };

    return (
        <div>
            {!isRegistered ? (
                <form onSubmit={handleRegister}>
                    <div>
                        <label>Email/Username:</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div>
                        <label>Password:</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <button type="submit">Register</button>
                </form>
            ) : (
                <form onSubmit={handleVerify}>
                    <div>
                        <label>Verification Code:</label>
                        <input
                            type="text"
                            value={verificationCode}
                            onChange={(e) => setVerificationCode(e.target.value)}
                        />
                    </div>
                    <button type="submit">Verify</button>
                </form>
            )}
            {error && <div style={{ color: 'red' }}>{error}</div>}
            {message && <div style={{ color: 'green' }}>{message}</div>}
        </div>
    );
};

export default Register;
