import React, { useState, useEffect } from 'react';
import './Transactions.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Quests = () => {
    const [quests, setQuests] = useState([]);
    const [overlayVisible, setOverlayVisible] = useState(false);
    const [overlayContent, setOverlayContent] = useState(null);
    const [overlayClickCount, setOverlayClickCount] = useState(0);
    const [balance, setBalance] = useState(0); 
	
    const userId = '3428d458-7051-7001-444d-f6afbd8b8f93';
    const optionsApiUrl = `https://vppmbp4zp4.execute-api.us-east-1.amazonaws.com/api/v1/users/${userId}/options`;
    const questsApiUrl = `https://vppmbp4zp4.execute-api.us-east-1.amazonaws.com/api/v1/quests`;
    const token = localStorage.getItem('idToken');
    const navigate = useNavigate();

    const handleOverlayClick = () => {
        if (overlayClickCount === 0) {
            setOverlayClickCount(1);
        } else {
            setOverlayVisible(false);
            setOverlayContent(null);
        }
    };

    // Fetch balance
    useEffect(() => {
        const fetchBalance = async () => {
            try {
                const response = await fetch(optionsApiUrl, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (response.status === 401) {
                    localStorage.removeItem('idToken');
                    navigate('/');
                    return;
                }

                const data = await response.json();
                if (data && data.data) {
                    const resource = data.data.find(option => option.option === 'resourceGacha');
                    if (resource) {
                        setBalance(Number(resource.value));
                    }
                }
            } catch (error) {
                console.error('Error fetching balance:', error);
            }
        };

        fetchBalance();
    }, [optionsApiUrl, navigate, token]);

    // Fetch quests
    useEffect(() => {
        const fetchQuests = async () => {
            try {
                const response = await fetch(questsApiUrl, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const data = await response.json();
                const parsedData = data.data || []; // Ensure parsedData is an array
                setQuests(parsedData);
            } catch (error) {
                console.error("Failed to fetch quests:", error);
            }
        };

        fetchQuests();
    }, [questsApiUrl, token]);

    const handleCollect = async (id, index) => {
        try {
            const response = await fetch(`https://vppmbp4zp4.execute-api.us-east-1.amazonaws.com/api/v1/quests/collect/${id}`, {
                method: 'PUT'
            });

            if (response.ok) {
                const updatedQuest = { ...quests[index], status: { S: 'collected' } };

                setQuests(prevQuests =>
                    prevQuests.map((quest, i) =>
                        i === index ? updatedQuest : quest
                    )
                );

                // Set overlay content and show overlay
                setOverlayContent({
                    rewardCount: updatedQuest.rewardCount?.S,
                    rewardType: updatedQuest.rewardType?.S,
                });
                setOverlayClickCount(0);
                setOverlayVisible(true);
            } else {
                console.error('Failed to collect reward');
            }
        } catch (error) {
            console.error('Error while collecting reward:', error);
        }
    };

    return (
        <div className="container">
            <header className="header">
                <div className="header-top">
                    <a href="#">
                        <img src="icons/left-arrow.svg" alt="back" />
                    </a>
                    <span>Quests</span>
                    <button>
                        <img src="icons/burger-menu.svg" alt="burger-menu" />
                    </button>
                </div>
                <div className="header-bottom">
                    <button>
                        <img src="icons/calendar.svg" alt="calendar" />
                    </button>
                    <button>
                        <img src="icons/search.svg" alt="search" />
                    </button>
                </div>
            </header>
            <main>
                {overlayVisible && (
                    <div className="overlay" onClick={handleOverlayClick}>
                        <div className="overlay-content">
                            {overlayClickCount === 0 && (
                                <h2>QUEST COMPLETED</h2> // Display a message on first appearance
                            )}
                            {overlayClickCount === 1 && overlayContent && (
                                <div>
                                    <div className="reward-count">+{overlayContent.rewardCount}</div>
                                    <div className="reward-type">{overlayContent.rewardType}</div>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                <div className="balance">
                    <span className="balance__value">${balance.toFixed(2)}</span>
                    <span className="balance__text">Real-Time Balance</span>
                </div>
                <h2 className="title-section">Quests</h2>
                {quests.map((quest, index) => (
                    <div key={index} className={`transaction ${quest.status?.S === 'done' ? 'done' : ''}`}>
                        <div className="transaction__date">
                            <span className="transaction__data-month">{quest.progressCount?.S || 'N/A'}</span>
                            <span className="transaction__data-day">out of</span>
                            <span className="transaction__data-year">{quest.progressGoal?.S || 'N/A'}</span>
                        </div>
                        <h3 className="transaction__title">
                            {quest.name?.S || 'Unknown Quest'}
                            {quest.status?.S === 'uncollected' && (
                                <button
                                    className="collect-button"
                                    onClick={() => handleCollect(quest.id.S, index)}
                                    disabled={quest.status?.S === 'collected'}
                                >
                                    {quest.status?.S === 'collected' ? 'Collected' : 'Collect'}
                                </button>
                            )}
                        </h3>
                        <div className="transaction__data">
                            <span className="transaction__value">+{quest.rewardCount?.S || '0.00'}</span>
                            <span className="transaction__balance">Reward: {quest.rewardType?.S || 'Unknown'}</span>
                        </div>
                    </div>
                ))}
            </main>
            <footer className="footer">
                <button className="active">
                    <img src="icons/dollar.svg" alt="dollar" />
                </button>
                <Link to="/codex">
                    <button>
                        <img src="icons/doc-text.svg" alt="doc" />
                    </button>
                </Link>
                <button>
                    <img src="icons/list.svg" alt="list" />
                </button>
                <button>
                    <img src="icons/info.svg" alt="info" />
                </button>
            </footer>
        </div>
    );
};

export default Quests;
