import React, { useEffect, useState } from 'react';
import './Transactions.css';
import { Link } from 'react-router-dom';

const Transactions = () => {
    const [transactions, setTransactions] = useState([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(true); // New state for loading

    useEffect(() => {
        fetchTransactions();
    }, []);

	const fetchTransactions = async () => {
		const apiUrl = 'https://sirotin.us/pocketBank/emailAPI.php'; // Your API URL
		try {
			const response = await fetch(apiUrl);
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			const data = await response.json();
			if (data.error) {
				console.error('Error fetching transactions:', data.message);
				return;
			}
			setTransactions(data.transactions.reverse());
			setTotal(parseFloat(data.total)); // Ensure total is a number
		} catch (error) {
			console.error('Error fetching transactions:', error);
		} finally {
            setLoading(false); // Set loading to false after API call completes
        }
	};

    if (loading) {
        return (
            <div className="loading-screen">
                <span>Loading...</span>
            </div>
        );
    }

    return (
        <div className="container">
            <header className="header">
                <div className="header-top">
                    <a href="#">
                        <img src="icons/left-arrow.svg" alt="back" />
                    </a>
                    <span>Checking ***-519</span>
                    <button>
                        <img src="icons/burger-menu.svg" alt="burger-menu" />
                    </button>
                </div>
                <div className="header-bottom">
                    <button>
                        <img src="icons/calendar.svg" alt="calendar" />
                    </button>
                    <button>
                        <img src="icons/search.svg" alt="search" />
                    </button>
                </div>
            </header>
            <main>
                <div className="balance">
                    <span className="balance__value">${isNaN(total) ? 'Loading...' : total.toFixed(2)}</span>
                    <span className="balance__text">Real-Time Balance</span>
                </div>
                <h2 className="title-section">Transactions</h2>
                {transactions.map(transaction => (
                    <div key={transaction.id} className="transaction">
                        <div className="transaction__date">
                            <span className="transaction__data-month">{transaction.date.split(" ")[0]}</span>
                            <span className="transaction__data-day">{transaction.date.split(" ")[1].replace(',', '')}</span>
                            <span className="transaction__data-year">{transaction.date.split(" ")[2]}</span>
                        </div>
                        <h3 className="transaction__title">{transaction.merchant || transaction.title}</h3>
                        <div className="transaction__data">
                            <span className="transaction__value">-<sup>$</sup>{transaction.value}</span>
                            <span className="transaction__balance">Balance: ${transaction.balance}</span>
                        </div>
                    </div>
                ))}
            </main>
            <footer className="footer">
                <button className="active">
                    <img src="icons/dollar.svg" alt="dollar" />
                </button>
				<Link to="/codex">
                <button>
                    <img src="icons/doc-text.svg" alt="doc" />
                </button>
				</Link>
                <button>
                    <img src="icons/list.svg" alt="list" />
                </button>
                <button>
                    <img src="icons/info.svg" alt="info" />
                </button>
            </footer>
        </div>
    );
};

export default Transactions;
