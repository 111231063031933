import React, { useEffect, useState } from 'react';

const Anki = () => {
    const [wrong, setWrong] = useState(0);
    const [highscore, setHighscore] = useState(0);
    const [scores, setScores] = useState([]);

    useEffect(() => {
        const loadScript = (src) => {
            // Check if the script already exists
            if (!document.querySelector(`script[src="${src}"]`)) {
                const script = document.createElement('script');
                script.src = src;
                script.async = true;
                document.body.appendChild(script);
                return () => {
                    document.body.removeChild(script); // Clean up script when component unmounts
                };
            }
        };
    
        const cleanupFlipCardJs = loadScript('/anki/flipCard.js');
        const cleanupWordListJs = loadScript('/anki/wordList.js');
        const cleanupPlayerListJs = loadScript('/anki/playerList.js');
        const cleanupGptJs = loadScript('/anki/gpt.js');
    
        // Clean up scripts when component unmounts
        return () => {
            if (cleanupFlipCardJs) cleanupFlipCardJs();
            if (cleanupWordListJs) cleanupWordListJs();
            if (cleanupPlayerListJs) cleanupPlayerListJs();
            if (cleanupGptJs) cleanupGptJs();
        };
    }, []);
    

    const saveScore = (score) => {
        const scoreEntry = { score, timestamp: new Date().toISOString() };
        const scoresKey = 'topScores';
        let currentScores = JSON.parse(localStorage.getItem(scoresKey)) || [];
        
        currentScores.push(scoreEntry);
        currentScores.sort((a, b) => b.score - a.score);
        currentScores = currentScores.slice(0, 10);

        localStorage.setItem(scoresKey, JSON.stringify(currentScores));
        setScores(currentScores);
    };

    const displayScores = () => {
        const scoresKey = 'topScores';
        const storedScores = JSON.parse(localStorage.getItem(scoresKey)) || [];
        setScores(storedScores);
    };

    const wrongAnswer = () => {
        console.log("wrong!");
        setWrong(wrong + 1);
        if (wrong + 1 === 3) {
            document.getElementById("highscore").innerHTML = highscore;
            document.getElementById('hiddenDiv').style.display = 'block';
            saveScore(highscore);
        }
    };

    const rightAnswer = (quizWord, button) => {
        console.log("right answer:", quizWord);
        button.disabled = false;
        button.click();
        button.disabled = false;
    };

    return (
        <div>
            {/* Include CSS directly */}
            <link rel="stylesheet" href="/anki/flipCard.css" />

            <div className="card-container">
                {[0, 1, 2].map((i) => (
                    <div key={i}>
                        <button id={`b${i + 1}`} className="btn btn--blue" data-card={i}>Flip Card {i + 1}</button>
                        <div className="card js-card">
                            <div className="card__wrapper">
                                <div className="card__side is-active">
                                    <p></p><br />
                                    <p></p><br />
                                    <p></p><br />
                                </div>
                                <div className="card__side card__side--back">
                                    <div className={`study-area`} id={`study-area${i + 1}`}>
                                        <p className="definition">Loading...</p>
                                        {[...Array(4)].map((_, idx) => (
                                            <button key={idx} className="option" data-flip-button={`b${i + 1}`}>Loading...</button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h3 className="score" data-score="0">TOTAL SCORE: 0</h3>
                    </div>
                ))}
            </div>
            <div id="hiddenDiv" style={{ display: 'none' }}>
                <h1>GAME OVER!</h1>
                <h2>FINAL SCORE: <span id="highscore"></span></h2>
                <button onClick={() => window.location.reload()}>NEW GAME</button>
            </div>
            <div>
                <h1>RECORD: <span id="record"></span></h1>
            </div>
            <div id="scoresDiv">
                {scores.map((score, index) => (
                    <div key={index}>
                        Rank {index + 1}: {score.score} - {score.timestamp}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Anki;
