// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './Home';
import Codex from './Codex';
import Gacha from './Gacha';
import Quests from './Quests';
import Anki from './Anki';

function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/codex" element={<Codex />} />
					<Route path="/gacha" element={<Gacha />} />
					<Route path="/quests" element={<Quests />} />
					<Route path="/anki" element={<Anki />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
