// src/Login.js
import React, { useState } from 'react';
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';

const poolData = {
    UserPoolId: 'us-east-1_VHDa4Ftzs', // Replace with your User Pool ID
    ClientId: '1bscmli1vv0cd0hfipcfanht6r'   // Replace with your App Client ID
};

const userPool = new CognitoUserPool(poolData);

const Login = ({ onAuthenticated }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();

        const authenticationDetails = new AuthenticationDetails({
            Username: email,
            Password: password
        });

        const cognitoUser = new CognitoUser({
            Username: email,
            Pool: userPool
        });

		cognitoUser.authenticateUser(authenticationDetails, {
			onSuccess: (result) => {
				const idToken = result.getIdToken().getJwtToken();
				localStorage.setItem('idToken', idToken);
				onAuthenticated(true, idToken); // Pass the ID token instead of the access token
			},
			onFailure: (err) => {
				console.error(err);
			}
		});
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label>Email/Username:</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div>
                <label>Password:</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>
            <button type="submit">Login</button>
        </form>
    );
};

export default Login;
