import React, { useEffect, useState } from 'react';
import './Codex.css';
import { useNavigate } from 'react-router-dom';

const Gacha = () => {
	const [overlayVisible, setOverlayVisible] = useState(false);
	const [overlayContent, setOverlayContent] = useState(null);
	const [overlayClickCount, setOverlayClickCount] = useState(0);

	const [resourceGacha, setResourceGacha] = useState(0);
	const [gachaItems, setGachaItems] = useState([]);
	const navigate = useNavigate(); 

	const userId = '3428d458-7051-7001-444d-f6afbd8b8f93';
	const optionsApiUrl = `https://vppmbp4zp4.execute-api.us-east-1.amazonaws.com/api/v1/users/${userId}/options`;
	const token = localStorage.getItem('idToken');

	useEffect(() => {
		fetch(optionsApiUrl, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
			.then(response => {
				if (response.status === 401) {
					localStorage.removeItem('idToken');
					navigate('/'); 
					return null;
				}
				return response.json();
			})
			.then(data => {
				if (data && data.data) {
					const resource = data.data.find(option => option.option === 'resourceGacha');
					if (resource) {
						setResourceGacha(Number(resource.value));
					}
				}
			})
			.catch(error => {
				console.error('Error fetching resourceGacha:', error);
			});

	}, [optionsApiUrl, navigate, token]);

	const handleGacha = () => {
		const gachaUrl = `https://vppmbp4zp4.execute-api.us-east-1.amazonaws.com/api/v1/gacha/1`;

		fetch(gachaUrl, {
			method: 'POST',
			headers: {
				'Authorization': `Bearer ${token}`
			}
		})
			.then(response => response.json())
			.then(data => {
				if (data && data.codexId) {
					const newItem = {
						word: data.word,
						definition: data.definition,
						codexId: data.codexId,
						rarity: data.rarity
					};
					setGachaItems(prevItems => [
						...prevItems,
						newItem
					]);

					const rarityTextMap = {
						1: 'Common',
						2: 'Uncommon',
						3: 'Rare',
						4: 'Epic',
						5: 'Legendary'
					};

					setOverlayContent({
						...newItem,
						color: getRarityClass(newItem.rarity),
						rarityText: rarityTextMap[newItem.rarity]
					});

					setOverlayClickCount(0);
					setOverlayVisible(true);
				}
			})
			.catch(error => {
				console.error('Error fetching gacha:', error);
			});
	};

	const calculatePower = (codexId, rarity) => {
		const power = Math.round(100 * Math.pow(2, codexId / 100) * parseInt(rarity) / 2);
		const units = ["", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
		let unitIndex = 0;
		let displayPower = power;

		while (displayPower >= 1000000 && unitIndex < units.length - 1) {
			displayPower /= 1000000;
			unitIndex++;
		}

		return `${Math.round(displayPower).toLocaleString()} ${units[unitIndex]}`.trim();
	};

	const getRandomEffectClass = () => {
		const effectNumber = Math.floor(Math.random() * 8) + 1;
		return `box effect${effectNumber}`;
	};

	const getRarityClass = (rarity) => {
		switch (rarity) {
			case 1:
				return 'border-grey';
			case 2:
				return 'border-green';
			case 3:
				return 'border-blue';
			case 4:
				return 'border-purple';
			case 5:
				return 'border-orange';
			default:
				return '';
		}
	};

	const handleOverlayClick = () => {
		if (overlayClickCount === 0) {
			setOverlayClickCount(1);
		} else {
			setOverlayVisible(false);
			setOverlayContent(null);
		}
	};

	const handleNavigateToCodex = () => {
		navigate('/codex'); // Navigate to the /codex page
	};
	const handleNavigateToGacha = () => {
		navigate('/gacha'); // Navigate to the /codex page
	};	

	return (
		<div className="gacha-container">
			<div style={{ display: 'flex', gap: '10px' }}>
				<button 
					onClick={handleNavigateToCodex} 
					className="codex-button" 
					style={{ display: 'inline-block' }}
				>
					Go to Codex
				</button>
				<button 
					onClick={handleNavigateToGacha} 
					className="codex-button" 
					style={{ display: 'inline-block' }}
				>
					Go to Gacha
				</button>
			</div>		
			<h1 className="resource-gacha">Resource Gacha: {resourceGacha}</h1>
			<button onClick={handleGacha} className="gacha-button">
				Gacha Pull
			</button>
			<div className="card-container">
				{gachaItems.map((item, index) => (
					<div key={index} className={`card ${getRarityClass(item.rarity)} ${getRandomEffectClass()}`}>
						<div className="word">{item.word}</div>
						<div className="definition">{item.definition}</div>
						<div className="power">{calculatePower(item.codexId, item.rarity)}</div>
					</div>
				))}
			</div>

			{overlayVisible && (
				<div 
					className={`overlay ${overlayContent.color}`} 
					onClick={handleOverlayClick}
				>
					<div className="overlay-content">
						{overlayClickCount === 0 && (
							<h2>{overlayContent.rarityText}</h2>
						)}
						{overlayClickCount === 1 && overlayContent && (
							<div className={`card ${getRarityClass(overlayContent.rarity)}`}>
								<div className="word">{overlayContent.word}</div>
								<div className="definition">{overlayContent.definition}</div>
								<div className="power">{calculatePower(overlayContent.codexId, overlayContent.rarity)}</div>
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default Gacha;
