import React, { useState, useEffect } from 'react';
import Login from './Login';
import Register from './Register';
import Transactions from './Transactions';

function Home() {
    const [isRegistering, setIsRegistering] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [token, setToken] = useState('');

    useEffect(() => {
        const savedToken = localStorage.getItem('idToken');
        console.log("useEffect triggered");
        console.log("Saved Token:", savedToken);

        if (savedToken) {
            console.log("Token is present, setting authenticated state.");
            setIsAuthenticated(true);
            setToken(savedToken);
        } else {
            console.log("No valid token found.");
        }
    }, []);

    const handleAuthentication = (status, token = '') => {
        console.log("Handling authentication:", status, token);
        setIsAuthenticated(status);
        setToken(token);

        if (status && token) {
            localStorage.setItem('token', token);
            console.log("Token saved to localStorage.");
        } else {
            localStorage.removeItem('token');
            console.log("Token removed from localStorage.");
        }
    };

    return (
        <div className="Home">
            <header className="Home-header">
                {isAuthenticated ? (
                    <Transactions />
                ) : (
                    <>
                        {isRegistering ? (
                            <Register onAuthenticated={handleAuthentication} />
                        ) : (
                            <Login onAuthenticated={handleAuthentication} />
                        )}
                        <button onClick={() => setIsRegistering(!isRegistering)}>
                            {isRegistering ? 'Already have an account? Log in' : 'Need an account? Register'}
                        </button>
                    </>
                )}
            </header>
        </div>
    );
}

export default Home;
